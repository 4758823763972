import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Keyboard, TouchableWithoutFeedback, Platform, ActivityIndicator, Alert } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { API_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';
import * as SecureStore from 'expo-secure-store';
import DeviceInfo from 'react-native-device-info'; // Importă pentru a obține informațiile despre dispozitiv

export default function LoginScreen() {
  const { colors } = useTheme();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [remainingAttempts, setRemainingAttempts] = useState(5); // Afișează numărul de încercări rămase
  const [retryAfter, setRetryAfter] = useState(null); // Afișează timpul rămas până la resetare
  const navigation = useNavigation();

  useEffect(() => {
    const loadRememberedData = async () => {
      const rememberedUsername = await AsyncStorage.getItem('username');
      let rememberedPassword;
      
      if (Platform.OS !== 'web') {
        rememberedPassword = await SecureStore.getItemAsync('password');
      } else {
        rememberedPassword = await AsyncStorage.getItem('password');
      }

      if (rememberedUsername && rememberedPassword) {
        setUsername(rememberedUsername);
        setPassword(rememberedPassword);
        setRememberMe(true);
      }
    };

    loadRememberedData();
  }, []);

  const getPublicIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Adresa IP publică
    } catch (error) {
      console.error('Error fetching public IP:', error);
      return null;
    }
  };


  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      return "Android";
    }
  
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  
    return "PC"; // Sau "Unknown" pentru alte cazuri
  };
  
  

  const handleLogin = async () => {
    if (!username.trim() || !password.trim()) {
      Alert.alert('Error', 'Username and password are required');
      return;
    }
  
    setLoading(true);
    setError('');
  
    try {
      // Obține detalii despre dispozitiv și IP
      const deviceType = getDeviceType();
      const ipAddress = await getPublicIP();
      const location = null;
  
      const response = await axios.post(`${API_URL}/login`, {
        username: username.trim(),
        password: password.trim(),
        device: deviceType,
        ip: ipAddress,
        location,
      });
  
      if (response.data.success) {
        const { token, user_id } = response.data;
  
        // Salvează token-ul și user_id în AsyncStorage
        await AsyncStorage.setItem('token', token);
        await AsyncStorage.setItem('user_id', user_id.toString());
  
        // Salvează numele de utilizator și parola dacă Remember Me este activat
        if (rememberMe) {
          await AsyncStorage.setItem('username', username);
          if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('password', password);
          } else {
            await AsyncStorage.setItem('password', password);
          }
        } else {
          // Șterge datele dacă Remember Me nu este activat
          await AsyncStorage.removeItem('username');
          if (Platform.OS !== 'web') {
            await SecureStore.deleteItemAsync('password');
          } else {
            await AsyncStorage.removeItem('password');
          }
        }
  
        // Notificare de securitate dacă e necesar
        if (response.data.notifyUser) {
          Alert.alert('Security Alert', 'You have logged in from a new device, IP address, or location.');
        }
  
        // Navigare la Home
        navigation.replace('HomeTabs');
      } else {
        setError(response.data.message);
        setRemainingAttempts(response.data.remainingAttempts || 5);
        setRetryAfter(response.data.retryAfter || null);
      }
    } catch (error) {
      console.error('Error during login:', error);
  
      if (error.response) {
        if (error.response.status === 401) {
          setError('Invalid username or password');
          setRemainingAttempts(error.response.data.remainingAttempts || 5);
        } else if (error.response.status === 403) {
          setError(`Account is locked. Please try again in ${error.response.data.retryAfter} minutes.`);
          setRetryAfter(error.response.data.retryAfter || null);
        } else if (error.response.status === 429) {
          setError(error.response.data.message);
        } else {
          setError('Error logging in');
        }
      } else {
        setError('Network error or server not reachable');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <TextInput
        style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
        placeholder="User name"
        value={username}
        onChangeText={setUsername}
        placeholderTextColor={colors.textSecondary}
      />
      <TextInput
        style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry={!showPassword}
        placeholderTextColor={colors.textSecondary}
      />
      <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
        <Text style={[styles.forgotPassword, { color: colors.primary }]}>
          {showPassword ? 'Hide Password' : 'Show Password'}
        </Text>
      </TouchableOpacity>
      <View style={styles.rememberMeContainer}>
        <TouchableOpacity onPress={() => setRememberMe(!rememberMe)}>
          <Text style={[styles.rememberMe, { color: colors.primary }]}>
            {rememberMe ? '☑' : '☐'} Remember Me
          </Text>
        </TouchableOpacity>
      </View>

      {error ? <Text style={styles.errorText}>{error}</Text> : null}

      {/* Afișăm numărul de încercări rămase și timpul până la resetare dacă există o eroare */}
      {remainingAttempts < 5 && retryAfter === null && (
        <Text style={styles.remainingAttemptsText}>
          Remaining attempts: {remainingAttempts}
        </Text>
      )}

      {retryAfter !== null && (
        <Text style={styles.lockoutText}>
          Too many failed attempts. Please try again in {retryAfter} minutes.
        </Text>
      )}

      {loading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : (
        <TouchableOpacity style={[styles.button, { backgroundColor: colors.primary }]} onPress={handleLogin}>
          <Text style={styles.buttonText}>Login</Text>
        </TouchableOpacity>
      )}

      <TouchableOpacity onPress={() => navigation.navigate('Signup')}>
        <Text style={[styles.signupText, { color: colors.text }]}>
          Don't have an account? <Text style={{ color: colors.primary }}>Sign Up</Text>
        </Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigation.navigate('RecoverPassword')}>
        <Text style={[styles.recoverPasswordText, { color: colors.primary }]}>
          Forgot your password? Recover Password
        </Text>
      </TouchableOpacity>
    </View>
  );

  return Platform.OS === 'web' ? (
    renderContent()
  ) : (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {renderContent()}
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  forgotPassword: {
    fontSize: 14,
    textAlign: 'right',
    marginBottom: 20,
  },
  rememberMeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  rememberMe: {
    fontSize: 14,
  },
  button: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  signupText: {
    fontSize: 14,
    textAlign: 'center',
  },
  recoverPasswordText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginBottom: 10,
  },
  remainingAttemptsText: {
    color: 'orange',
    textAlign: 'center',
    marginBottom: 10,
  },
  lockoutText: {
    color: 'red',
    textAlign: 'center',
    marginBottom: 10,
  },
});
