import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, ScrollView, Alert, TouchableOpacity, Dimensions } from 'react-native';
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

const { width } = Dimensions.get('window');

// Configurare Axios pentru a seta automat header-ul Authorization
axios.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found, cannot set Authorization header'); // Debugging log
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Funcție pentru a seta header-ul Authorization pentru toate cererile
const setAuthToken = async () => {
  try {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return token;
    } else {
      throw new Error('No token found');
    }
  } catch (error) {
    console.error('Error setting auth token:', error);
    return null;
  }
};

export default function RecipeDetailsPublicScreen({ route }) {
  const { recipeId, userId } = route.params;
  const [recipe, setRecipe] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const { colors } = useTheme();

  useEffect(() => {
    const fetchRecipeDetails = async () => {
      try {
        const token = await setAuthToken(); // Set token in headers

        if (!token) {
          Alert.alert('Error', 'No token found. Please log in again.');
          return;
        }

        // Fetch recipe details
        const response = await axios.get(`${API_URL}/recipes/${recipeId}`);
        setRecipe(response.data.recipe);

        // Fetch state details (favorite)
        const stateResponse = await axios.get(`${API_URL}/recipestate/${recipeId}`, {
          params: { user_id: userId },
        });

        if (stateResponse.data.state) {
          setIsFavorite(Number(stateResponse.data.state.favorite) === 1);
        }
      } catch (error) {
        console.error('Error fetching recipe details or state:', error);
        Alert.alert('Error fetching recipe details');
      }
    };

    if (userId) {
      fetchRecipeDetails();
    }
  }, [recipeId, userId]);

  const handleFavoritePress = async () => {
    try {
      const token = await setAuthToken(); // Set token in headers

      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        return;
      }

      const newFavoriteStatus = !isFavorite;
      setIsFavorite(newFavoriteStatus);

      const response = await axios.post(`${API_URL}/recipestate/favorite`, {
        user_id: userId,
        recipe_id: recipeId,
        favorite: newFavoriteStatus ? 1 : 0,
      });

      if (!response.data.success) {
        // If the server fails, revert the state
        setIsFavorite(!newFavoriteStatus);
        Alert.alert('Error', 'Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
      Alert.alert('Error updating favorite status');
    }
  };

  if (!recipe) {
    return (
      <View style={[styles.loadingContainer, { backgroundColor: colors.background }]}>
        <Text style={{ color: colors.text }}>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <Image 
          source={{ uri: `${IMAGE_URL}/photos/${recipe.image || 'default.png'}` }} 
          style={styles.image} 
          onError={() => setRecipe({ ...recipe, image: 'default.png' })}
        />
        <View style={[styles.contentContainer, { backgroundColor: colors.background }]}>
          <View style={styles.header}>
            <Text style={[styles.title, { color: colors.text }]}>{recipe.title}</Text>
            <View style={styles.infoContainer}>
              <Text style={[styles.time, { color: colors.primary }]}>{`${recipe.time} min`}</Text>
              <View style={styles.actionContainer}>
                <TouchableOpacity onPress={handleFavoritePress}>
                  <Ionicons 
                    name={isFavorite ? 'heart' : 'heart-outline'} 
                    size={28} 
                    color={isFavorite ? 'red' : colors.text} 
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={[styles.sectionTitle, { color: colors.text }]}>Ingredients</Text>
            {recipe.ingredients.map((ingredient, index) => (
              <Text key={index} style={[styles.text, { color: colors.text }]}>{`${ingredient.ingrediente}: ${ingredient.cantitate} ${ingredient.unitate_de_masura}`}</Text>
            ))}
          </View>
          <View style={styles.section}>
            <Text style={[styles.sectionTitle, { color: colors.text }]}>Instructions</Text>
            <Text style={[styles.text, { color: colors.text }]}>{recipe.instructions}</Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: width,
    height: 300,
    marginBottom: -30,
  },
  contentContainer: {
    flex: 1,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 16,
    paddingTop: 40,
  },
  header: {
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  time: {
    fontSize: 16,
  },
  actionContainer: {
    flexDirection: 'row',
  },
  section: {
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    marginBottom: 8,
  },
});
