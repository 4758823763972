import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Alert, Image, Platform } from 'react-native';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL, IMAGE_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';

export default function UpdateContactDetailsScreen({ navigation }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarMessage, setAvatarMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const { colors } = useTheme();
  const fileInputRef = useRef(null);

  // Configure Axios to set the Authorization header for all requests
  axios.interceptors.request.use(
    async (config) => {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('No token found, cannot set Authorization header'); // Debugging log
      }
      return config;
    },
    (error) => {
      console.error('Error in request interceptor:', error);
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const getUserId = async () => {
      const id = await AsyncStorage.getItem('user_id');
      if (!id) {
        Alert.alert('Error', 'User not logged in');
        navigation.navigate('Login');
        return;
      }
      setUserId(id);
      fetchUserDetails(id);
    };

    getUserId();
  }, []);

  const fetchUserDetails = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/user/${id}`);
      const user = response.data.user;
      setUsername(user.username);
      setEmail(user.email);
      setAvatar(user.avatar ? { uri: `${IMAGE_URL}/avatar/${user.avatar}` } : null);
    } catch (error) {
      console.error(error);
      Alert.alert('Error fetching user details');
    }
  };

  const handleUpdateContactDetails = async () => {
    if (!username || !email) {
      Alert.alert('Error', 'Username and Email are required');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('email', email);

      if (avatar && avatar.uri && !avatar.uri.includes(IMAGE_URL)) {
        if (Platform.OS === 'web') {
          formData.append('avatar', avatar.file);
        } else {
          formData.append('avatar', {
            uri: avatar.uri,
            type: 'image/jpeg',
            name: avatar.uri.split('/').pop(),
          });
        }
      }

      const response = await axios.put(`${API_URL}/user/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        Alert.alert('Success', 'Contact details updated');
        navigation.goBack();
      } else {
        Alert.alert('Error', 'Failed to update contact details');
      }
    } catch (error) {
      console.error(error);
      Alert.alert('Error', 'Failed to update contact details');
    }
  };

  const handleChooseAvatar = async () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setAvatar(result.assets[0]);
        setAvatarMessage('Avatar selected');
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localUri = URL.createObjectURL(file);
      setAvatar({ uri: localUri, file: file, name: file.name, type: file.type });
      setAvatarMessage('Avatar selected');
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Text style={[styles.title, { color: colors.text }]}>Update Contact Details</Text>
      <TextInput
        style={[styles.input, { backgroundColor: colors.card, color: colors.text, borderColor: colors.border }]}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
        placeholderTextColor={colors.textSecondary}
      />
      <TextInput
        style={[styles.input, { backgroundColor: colors.card, color: colors.text, borderColor: colors.border }]}
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        placeholderTextColor={colors.textSecondary}
      />
      <TouchableOpacity onPress={handleChooseAvatar} style={[styles.button, { backgroundColor: colors.primary }]}>
        <Text style={styles.buttonText}>Choose Avatar</Text>
      </TouchableOpacity>
      {Platform.OS === 'web' && (
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      )}
      {avatar && (
        <>
          <Text style={[styles.imageSelectedText, { color: colors.success }]}>{avatarMessage}</Text>
          <Image source={{ uri: avatar.uri }} style={styles.image} />
        </>
      )}
      <TouchableOpacity style={[styles.button, { backgroundColor: colors.primary }]} onPress={handleUpdateContactDetails}>
        <Text style={styles.buttonText}>Update</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  addImageText: {
    textAlign: 'center',
    marginVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
  },
  imageSelectedText: {
    textAlign: 'center',
    marginVertical: 8,
    fontSize: 16,
  },
  image: {
    width: '100%',
    height: 200,
    marginTop: 16,
    borderRadius: 10,
    marginBottom: 16,
  },
  button: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});
