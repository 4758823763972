import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet, Alert, Keyboard, TouchableOpacity, TouchableWithoutFeedback, Platform, ActivityIndicator } from 'react-native';
import axios from 'axios';
import { API_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';

export default function RecoverPasswordScreen({ navigation }) {
  const { colors } = useTheme();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // Indicator pentru încărcare
  const [errorMessage, setErrorMessage] = useState(''); // Mesaj de eroare

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRecoverPassword = async () => {
    if (!email) {
      setErrorMessage('Please provide your email.');
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    setLoading(true); // Pornește indicatorul de încărcare
    setErrorMessage(''); // Resetează mesajele de eroare

    try {
      const response = await axios.post(`${API_URL}/recover-password`, { email });
      setLoading(false); // Oprește indicatorul de încărcare imediat după primirea răspunsului

      if (response.data.success) {
        Alert.alert(
          'Success',
          response.data.message,
          [
            {
              text: 'OK',
              onPress: () => {
                setEmail(''); // Resetează câmpul email
                navigation.navigate('Login'); // Navighează la pagina de Login
              }
            }
          ]
        );
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false); // Oprește indicatorul de încărcare în caz de eroare
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  const renderContent = () => (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Text style={[styles.title, { color: colors.text }]}>Recover Password</Text>
      
      {/* Afișare mesaj de eroare */}
      {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
      
      <TextInput
        style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        placeholderTextColor={colors.textSecondary}
        keyboardType="email-address"
        autoCapitalize="none"
      />

      {loading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : (
        <TouchableOpacity style={[styles.button, { backgroundColor: colors.primary }]} onPress={handleRecoverPassword}>
          <Text style={styles.buttonText}>Send Recovery Email</Text>
        </TouchableOpacity>
      )}
    </View>
  );

  return Platform.OS === 'web' ? (
    renderContent()
  ) : (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {renderContent()}
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  button: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
    textAlign: 'center',
  },
});
