import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import axios from 'axios';
import { IMAGE_URL, API_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function AccountDetailsScreen({ route, navigation }) {
  const { colors, isDark } = useTheme(); // Use theme
  const [user, setUser] = useState(route.params.user);

  useFocusEffect(
    React.useCallback(() => {
      const fetchUpdatedUserDetails = async () => {
        try {
          const token = await AsyncStorage.getItem('token'); // Obține token-ul din stocarea locală
          const response = await axios.get(`${API_URL}/user/${user.id}`, {
            headers: {
              'Authorization': `Bearer ${token}` // Adaugă token-ul în antetul cererii
            }
          });
          setUser(response.data.user);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
  
      fetchUpdatedUserDetails();
    }, [])
  );
  

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Image
        source={{ uri: `${IMAGE_URL}/avatar/${user.avatar || 'default_avatar.png'}` }}
        style={styles.avatarTop}
      />
      <View style={styles.header}>
        <Text style={[styles.username, { color: colors.text }]}>{user.username}</Text>
      </View>
      <View style={[styles.detailsContainer, { backgroundColor: colors.card, shadowColor: colors.shadow }]}>
        <View style={styles.detailItem}>
          <Text style={[styles.detailLabel, { color: colors.textSecondary }]}>Email:</Text>
          <Text style={[styles.detailValue, { color: colors.text }]}>{user.email}</Text>
        </View>
        <View style={styles.detailItem}>
          <Text style={[styles.detailLabel, { color: colors.textSecondary }]}>Joined:</Text>
          <Text style={[styles.detailValue, { color: colors.text }]}>{new Date(user.created_at).toLocaleDateString()}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={[styles.modifyButton, { backgroundColor: colors.primary }]}
        onPress={() => navigation.navigate('UpdateContactDetails', { user })}
      >
        <Text style={styles.modifyButtonText}>Modify Contact Details</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  avatarTop: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    resizeMode: 'cover',
  },
  header: {
    alignItems: 'center',
    marginBottom: 32,
  },
  username: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  detailsContainer: {
    width: '90%',
    borderRadius: 8,
    padding: 16,
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    marginBottom: 32,
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  detailLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  detailValue: {
    fontSize: 16,
  },
  modifyButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    width: '90%',
  },
  modifyButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});
