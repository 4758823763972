import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Platform, KeyboardAvoidingView, ScrollView, Alert, Image, Modal, TouchableWithoutFeedback } from 'react-native';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { API_URL } from '../services/api';
import { Picker } from '@react-native-picker/picker';
import { useTheme } from '../context/ThemeContext';

export default function AddRecipeScreen() {
  const { colors, isDark } = useTheme();
  const [title, setTitle] = useState('');
  const [ingredients, setIngredients] = useState([{ ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
  const [instructions, setInstructions] = useState('');
  const [time, setTime] = useState('');
  const [image, setImage] = useState(null);
  const [imageMessage, setImageMessage] = useState('');
  const [category, setCategory] = useState('');
  const [isCategoryPickerVisible, setCategoryPickerVisibility] = useState(false); 
  const { showActionSheetWithOptions } = useActionSheet();
  const navigation = useNavigation();
  const [userId, setUserId] = useState(null);
  const [inputHeight, setInputHeight] = useState(100);
  const [selectedIngredientIndex, setSelectedIngredientIndex] = useState(null);
  const [isPickerVisible, setPickerVisibility] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const getUserId = async () => {
      const id = await AsyncStorage.getItem('user_id');
      setUserId(id);
    };

    getUserId();

    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setTitle('');
        setIngredients([{ ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
        setInstructions('');
        setTime('');
        setImage(null);
        setImageMessage('');
        setCategory('');
        setInputHeight(100);
        setSelectedIngredientIndex(null);
        setPickerVisibility(false);
        setCategoryPickerVisibility(false); 
      };
    }, [])
  );

  const setAuthToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return token;
      } else {
        console.warn('No token found, cannot set Authorization header');
        return null;
      }
    } catch (error) {
      console.error('Error setting auth token:', error);
      return null;
    }
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, { ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];
    if (newIngredients[index]) {
      newIngredients[index][field] = value;
      setIngredients(newIngredients);
    }
  };

  const handleAddRecipe = async () => {
    if (!userId) {
      Alert.alert('Error', 'User not logged in');
      return;
    }

    if (!title || !instructions || !time || !category || ingredients.some(ing => !ing.ingrediente)) {
      Alert.alert('Error', 'All fields are required, including category');
      return;
    }

    const timeInMinutes = parseInt(time, 10);

    try {
      const token = await setAuthToken();
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('title', title);
      formData.append('instructions', instructions);
      formData.append('time', timeInMinutes);
      formData.append('category', category); 
      formData.append('ingredients', JSON.stringify(ingredients));

      if (image) {
        if (Platform.OS === 'web') {
          formData.append('image', image.file);
        } else {
          formData.append('image', {
            uri: image.uri,
            type: 'image/jpeg',
            name: image.uri.split('/').pop(),
          });
        }
      }

      const response = await axios.post(`${API_URL}/recipes`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        const recipeId = response.data.recipeId;

        for (const ingredient of ingredients) {
          await axios.post(`${API_URL}/ingredients`, {
            recipeId,
            ingredient: ingredient.ingrediente,
            quantity: ingredient.cantitate,
            unit: ingredient.unitate_de_masura
          });
        }

        setTitle('');
        setIngredients([{ ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
        setInstructions('');
        setTime('');
        setImage(null);
        setCategory(''); 
        navigation.navigate('ViewRecipe');
      } else {
        Alert.alert('Error adding recipe');
      }
    } catch (error) {
      console.error('Error adding recipe:', error);
      Alert.alert('Error adding recipe');
    }
  };

  const handleChoosePhoto = async () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setImage(result.assets[0]);
        setImageMessage('Imaginea a fost selectată');
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localUri = URL.createObjectURL(file);
      setImage({ uri: localUri, file: file, name: file.name, type: file.type });
      setImageMessage('Imaginea a fost selectată');
    }
  };

  const showImagePickerOptions = () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      const options = ['Take Photo', 'Choose from Library', 'Cancel'];
      const cancelButtonIndex = 2;

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            handleChoosePhoto('Take Photo');
          } else if (buttonIndex === 1) {
            handleChoosePhoto('Choose from Library');
          }
        },
      );
    }
  };

  const handleUnitPress = (index) => {
    setSelectedIngredientIndex(index);
    setPickerVisibility(true);
  };

  const handlePickerChange = (value) => {
    if (selectedIngredientIndex !== null) {
      handleIngredientChange(selectedIngredientIndex, 'unitate_de_masura', value);
    }
    setPickerVisibility(false);
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 64 : 0}
    >
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={[styles.container, { backgroundColor: colors.background }]}>
          <TextInput
            style={[styles.input, { backgroundColor: colors.card, color: colors.text }]}
            placeholder="Title"
            value={title}
            onChangeText={setTitle}
            placeholderTextColor={colors.text}
          />
          {ingredients.map((ingredient, index) => (
            <View key={index} style={styles.ingredientContainer}>
              <TextInput
                style={[styles.input, { backgroundColor: colors.card, color: colors.text }]}
                placeholder="Ingredient"
                value={ingredient.ingrediente}
                onChangeText={(text) => handleIngredientChange(index, 'ingrediente', text)}
                placeholderTextColor={colors.text}
              />
              <View style={styles.quantityUnitContainer}>
                <TextInput
                  style={[styles.input, styles.quantityInput, { backgroundColor: colors.card, color: colors.text }]}
                  placeholder="Quantity"
                  value={ingredient.cantitate}
                  onChangeText={(text) => handleIngredientChange(index, 'cantitate', text)}
                  placeholderTextColor={colors.text}
                />
                <TouchableOpacity style={[styles.input, styles.unitInput, { backgroundColor: colors.card }]} onPress={() => handleUnitPress(index)}>
                  <Text style={[styles.unitInputText, { color: colors.text }]}>{ingredient.unitate_de_masura || 'Select Unit'}</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
          <TouchableOpacity onPress={handleAddIngredient} style={[styles.addIngredientButton, { backgroundColor: colors.primary }]}>
            <Text style={styles.addIngredientButtonText}>Add More Ingredients</Text>
          </TouchableOpacity>
          <TextInput
            style={[styles.textArea, { height: Math.max(100, inputHeight), backgroundColor: colors.card, color: colors.text }]}
            placeholder="Instructions"
            value={instructions}
            onChangeText={setInstructions}
            multiline
            onContentSizeChange={(event) => {
              setInputHeight(event.nativeEvent.contentSize.height);
            }}
            placeholderTextColor={colors.text}
          />
          <TextInput
            style={[styles.input, { backgroundColor: colors.card, color: colors.text }]}
            placeholder="Set Time (minutes)"
            value={time}
            onChangeText={setTime}
            keyboardType="numeric"
            placeholderTextColor={colors.text}
          />
          <TouchableOpacity 
            style={[styles.input, styles.unitCategory, { backgroundColor: colors.card }]} 
            onPress={() => setCategoryPickerVisibility(true)}
          >
            <Text style={[styles.unitInputText, { color: colors.text }]}>
              {category || 'Select Category'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={showImagePickerOptions} style={[styles.addRecipeButton, { backgroundColor: colors.primary }]}>
            <Text style={[styles.addImageText, { color: colors.text }]}>Add Image</Text>
          </TouchableOpacity>
          {image && (
            <>
              <Text style={[styles.imageSelectedText, { color: colors.success }]}>{imageMessage}</Text>
              <Image
                source={{ uri: image.uri }}
                style={styles.image}
              />
            </>
          )}
          <TouchableOpacity onPress={handleAddRecipe} style={[styles.addRecipeButton, { backgroundColor: colors.primary }]}>
            <Text style={styles.addRecipeButtonText}>Add Recipe</Text>
          </TouchableOpacity>
          {Platform.OS === 'web' && (
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
          )}
        </View>
        
        {/* Category Picker Modal */}
        <Modal
          visible={isCategoryPickerVisible}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setCategoryPickerVisibility(false)} // Close modal 
        >
          <TouchableWithoutFeedback onPress={() => setCategoryPickerVisibility(false)}>
            <View style={styles.pickerModal}>
              <TouchableWithoutFeedback>
                <View style={[styles.pickerContainer, { backgroundColor: colors.cardSelect }]}>
                  <Picker
                    selectedValue={category}
                    onValueChange={(itemValue) => {
                      setCategory(itemValue);
                      setCategoryPickerVisibility(false);
                    }}
                    style={{ color: colors.textSelect }}
                  >
                    <Picker.Item label="Select Category" value="" />
                    <Picker.Item label="Desert" value="Desert" />
                    <Picker.Item label="Supe" value="Supe" />
                    <Picker.Item label="Aperitiv" value="Aperitiv" />
                    <Picker.Item label="Fel Principal" value="Fel Principal" />
                    <Picker.Item label="Salată" value="Salată" />
                  </Picker>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>

        {/* Unit Picker Modal */}
        <Modal
          visible={isPickerVisible}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setPickerVisibility(false)} // Close modal 
        >
          <TouchableWithoutFeedback onPress={() => setPickerVisibility(false)}>
            <View style={styles.pickerModal}>
              <TouchableWithoutFeedback>
                <View style={[styles.pickerContainer, { backgroundColor: colors.cardSelect }]}>
                  <Picker
                    selectedValue={selectedIngredientIndex !== null ? ingredients[selectedIngredientIndex]?.unitate_de_masura : ''}
                    onValueChange={handlePickerChange}
                    style={{ color: colors.textSelect }}
                  >
                    <Picker.Item label="Select Unit" value="" />
                    <Picker.Item label="gr" value="gr" />
                    <Picker.Item label="Kg" value="Kg" />
                    <Picker.Item label="ml" value="ml" />
                    <Picker.Item label="L" value="L" />
                    <Picker.Item label="Bucata/i" value="Bucata/i" />
                    <Picker.Item label="Lingurita/e" value="Lingurita/e" />
                    <Picker.Item label="Lingura/i" value="Lingura/i" />
                    <Picker.Item label="Pliculet/e" value="Pliculet/e" />
                    <Picker.Item label="Pachet/e" value="Pachet/e" />
                  </Picker>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  ingredientContainer: {
    marginBottom: 12,
  },
  input: {
    height: 50,
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
    justifyContent: 'center', // Ensures content inside is centered vertically
  },
  quantityUnitContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  quantityInput: {
    flex: 0.48,
  },
  unitInput: {
    flex: 0.48,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  unitInputText: {
    textAlign: 'center',
    flex: 1, // Ensures the text fills the available space within the TouchableOpacity
    justifyContent: 'center', // Centers the text vertically
    lineHeight: 50, // Ensures the text is vertically centered
  },
  textArea: {
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    paddingTop: 10,
    fontSize: 16,
    textAlignVertical: 'top',
  },
  addIngredientButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  addIngredientButtonText: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  addImageText: {
    textAlign: 'center',
    marginVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
  },
  imageSelectedText: {
    textAlign: 'center',
    marginVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    height: 200,
    marginTop: 16,
    borderRadius: 10,
    marginBottom: 16,
  },
  addRecipeButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  addRecipeButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  unitCategory: {
    justifyContent: 'center', // Centers content vertically
    alignItems: 'center', // Centers content horizontally
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    height: 50, // Asigură aceeași înălțime ca celelalte inputuri
    width: '100%', // Setează lățimea să fie similară cu inputurile
  },
  pickerModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: Platform.OS === 'web' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)',
  },
  pickerContainer: {
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 20,
    borderRadius: 10,
    padding: 20,
    width: '100%',  // Adjust width to match the input fields
    maxWidth: 600, // Set a maximum width for the modal content
  },
});
