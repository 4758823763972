import React, { useState, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Image, Platform, ActivityIndicator, Alert } from 'react-native';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import { API_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';

export default function SignupScreen({ navigation }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarMessage, setAvatarMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Stare pentru indicatorul de încărcare
  const { colors } = useTheme();
  const fileInputRef = useRef(null);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getPublicIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Adresa IP publică
    } catch (error) {
      console.error('Error fetching public IP:', error);
      return null;
    }
  };
  

  const getDeviceType = () => {
    if (Platform.OS === 'ios') {
      return 'iOS';
    } else if (Platform.OS === 'android') {
      return 'Android';
    } else if (Platform.OS === 'web') {
      return 'PC';
    } else {
      return 'Unknown';
    }
  };

  const handleSignup = async () => {
    if (!username.trim() || !email.trim() || !password.trim()) {
      setErrorMessage('All fields are required');
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email format');
      return;
    }

    setLoading(true); // Pornește indicatorul de încărcare
    setErrorMessage(''); // Resetează mesajele de eroare

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('email', email);
      formData.append('password', password); // Trimite parola în clar

      const deviceType = getDeviceType(); // Obține tipul de dispozitiv
      formData.append('device', deviceType); // Adaugă tipul de dispozitiv

      const ipAddress = await getPublicIP(); // Obține adresa IP publică
      formData.append('ip', ipAddress); // Adaugă adresa IP

      const location = null // Obține locația pe baza IP-ului
      formData.append('location', location); // Adaugă locația

      if (avatar && avatar.uri) {
        if (Platform.OS === 'web') {
          formData.append('avatar', avatar.file);
        } else {
          formData.append('avatar', {
            uri: avatar.uri,
            type: 'image/jpeg',
            name: avatar.uri.split('/').pop(),
          });
        }
      } else {
        formData.append('avatar', 'avatar.png');
      }

      const response = await axios.post(`${API_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        Alert.alert('Success', 'Account created successfully. Please log in.');
        navigation.navigate('Login');
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error('Signup error:', error);

      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false); // Oprește indicatorul de încărcare
    }
  };

  const handleChooseAvatar = async () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setAvatar(result.assets[0]);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localUri = URL.createObjectURL(file);
      setAvatar({ uri: localUri, file: file, name: file.name, type: file.type });
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      {/* Afișare mesaj de eroare */}
      {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

      <TextInput
        style={[styles.input, { borderColor: colors.border, color: colors.text, backgroundColor: colors.card }]}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
        placeholderTextColor={colors.textSecondary}
      />
      <TextInput
        style={[styles.input, { borderColor: colors.border, color: colors.text, backgroundColor: colors.card }]}
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        placeholderTextColor={colors.textSecondary}
      />
      <TextInput
        style={[styles.input, { borderColor: colors.border, color: colors.text, backgroundColor: colors.card }]}
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        placeholderTextColor={colors.textSecondary}
      />

      <TouchableOpacity onPress={handleChooseAvatar} style={[styles.buttonAvatar, { backgroundColor: colors.primary }]}>
        <Text style={styles.buttonTextAvatar}>Choose Avatar</Text>
      </TouchableOpacity>

      {Platform.OS === 'web' && (
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      )}

      {avatar && (
        <>
          <Text style={[styles.imageSelectedText, { color: colors.text }]}>{avatarMessage}</Text>
          <Image
            source={{ uri: avatar.uri }}
            style={styles.image}
          />
        </>
      )}

      {loading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : (
        <TouchableOpacity style={[styles.button, { backgroundColor: colors.primary }]} onPress={handleSignup}>
          <Text style={styles.buttonText}>Sign Up</Text>
        </TouchableOpacity>
      )}

      <TouchableOpacity onPress={() => navigation.navigate('Login')}>
        <Text style={[styles.loginText, { color: colors.text }]}>Already have an account? <Text style={[styles.loginLink, { color: colors.primary }]}>Login here</Text></Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
    textAlign: 'center',
  },
  addImageText: {
    textAlign: 'center',
    marginVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    height: 200,
    marginTop: 16,
    borderRadius: 10,
    marginBottom: 16,
  },
  button: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  buttonAvatar: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  buttonTextAvatar: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  loginText: {
    fontSize: 14,
    textAlign: 'center',
  },
  loginLink: {
    fontWeight: 'bold',
  },
});
