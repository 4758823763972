import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Share, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';

export default function SettingsScreen() {
  const [user, setUser] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [themeText, setThemeText] = useState('Toggle Dark Mode');
  const navigation = useNavigation();
  const { colors, toggleTheme, isDark } = useTheme();

  // Fetch user data
  const fetchUserData = async () => {
    const userId = await AsyncStorage.getItem('user_id');
    if (userId) {
      try {
        const response = await axios.get(`${API_URL}/user/${userId}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
        Alert.alert('Error', 'Failed to fetch user data. Please log in again.');
        navigation.navigate('Login');
      }
    } else {
      Alert.alert('Error', 'User not logged in. Please log in again.');
      navigation.navigate('Login');
    }
  };

  // Handle side effects when the component is focused
  useFocusEffect(
    useCallback(() => {
      fetchUserData();
    }, [refreshKey])
  );

  useEffect(() => {
    setThemeText(isDark ? 'Toggle White Mode' : 'Toggle Dark Mode');
  }, [isDark]);

  const handleLogout = async () => {
    try {
      await AsyncStorage.removeItem('user_id');
      await AsyncStorage.removeItem('token'); // Clear token
  
      // Clear axios default authorization header
      axios.defaults.headers.common['Authorization'] = '';
  
      // Optional: Clear all interceptors
      axios.interceptors.request.handlers = []; // Clears all interceptors
  
      navigation.replace('Login');
    } catch (error) {
      console.error('Error during logout:', error);
      Alert.alert('Error', 'There was a problem logging out. Please try again.');
    }
  };
  

  const handleChangePassword = () => {
    navigation.navigate('ChangePassword');
  };

  const handleAccountDetails = () => {
    navigation.navigate('AccountDetails', { user });
  };

  const handleToggleTheme = () => {
    toggleTheme();
    setRefreshKey(prevKey => prevKey + 1);
  };

  const handleShareApp = async () => {
    try {
      const result = await Share.share({
        message: 'Check out this amazing app: https://cartedebucate.webcodeapp.ro',
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log(`Shared with activity type: ${result.activityType}`);
        } else {
          console.log('Shared successfully');
        }
      } else if (result.action === Share.dismissedAction) {
        console.log('Share dismissed');
      }
    } catch (error) {
      console.error('Error sharing the app:', error);
    }
  };

  if (!user) {
    return (
      <View style={[styles.container, { backgroundColor: colors.background }]}>
        <Text style={{ color: colors.text }}>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={styles.header}>
        <Image 
          source={{ uri: `${IMAGE_URL}/avatar/${user.avatar}` }} 
          style={styles.avatar} 
        />
        <Text style={[styles.username, { color: colors.text }]}>{user.username}</Text>
      </View>
      <TouchableOpacity style={styles.item} onPress={handleAccountDetails}>
        <Text style={{ color: colors.text, fontSize: 18 }}>Account</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.item} onPress={handleChangePassword}>
        <Text style={{ color: colors.text, fontSize: 18 }}>Change Password</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.item} onPress={handleShareApp}>
        <Text style={{ color: colors.text, fontSize: 18 }}>Share App</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.item} onPress={handleToggleTheme}>
        <Text style={{ color: colors.text, fontSize: 18 }}>{themeText}</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.item} onPress={handleLogout}>
        <Text style={{ color: colors.text, fontSize: 18 }}>Logout</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    alignItems: 'center',
    padding: 16,
    marginBottom: 16,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginBottom: 8,
  },
  username: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  item: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 8,
  },
});
