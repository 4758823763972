import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Base URLs for API and image assets
export const API_URL = 'https://srv571349.hstgr.cloud:5000/api';
export const IMAGE_URL = 'https://srv571349.hstgr.cloud:5000/assets';

// Axios interceptor to set Authorization header
axios.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found, cannot set Authorization header'); // Debugging log
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Function to get recipes
export const getRecipes = async () => {
  try {
    const response = await axios.get(`${API_URL}/recipes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recipes:', error);
    throw error;
  }
};

// Function to create a new recipe
export const createRecipe = async (recipe) => {
  try {
    const formData = new FormData();
    formData.append('user_id', recipe.user_id);
    formData.append('title', recipe.title);
    formData.append('ingredients', JSON.stringify(recipe.ingredients)); // Ingrediente trebuie să fie stringificat
    formData.append('instructions', recipe.instructions);
    formData.append('time', recipe.time);

    if (recipe.image && recipe.image.uri) {
      formData.append('image', {
        uri: recipe.image.uri,
        type: 'image/jpeg',
        name: recipe.image.uri.split('/').pop(),
      });
    }

    const response = await axios.post(`${API_URL}/recipes`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating recipe:', error);
    throw error;
  }
};
