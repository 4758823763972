import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Image, Alert } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTheme } from '../context/ThemeContext';
import { API_URL, IMAGE_URL } from '../services/api';
import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';

export default function FavoriteScreen({ navigation }) {
  const { colors } = useTheme();
  const [recipes, setRecipes] = useState([]);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUserId = await AsyncStorage.getItem('user_id');
        setUserId(storedUserId);
        const token = await setAuthToken();
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get(`${API_URL}/user/${storedUserId}`);
        setUsername(response.data.user.username);
      } catch (error) {
        console.error('Error fetching user data:', error);
        Alert.alert('Error', 'Failed to fetch user data. Please log in again.');
        navigation.navigate('Login');
      }
    };

    fetchUserData();
  }, []);

  useFocusEffect(
    useCallback(() => {
      const fetchFavoriteRecipes = async () => {
        try {
          if (!userId) return;
          const token = await setAuthToken();
          if (!token) {
            throw new Error('No token found');
          }

          const url = `${API_URL}/favorites?user_id=${userId}`;
          const response = await axios.get(url);
          setRecipes(response.data.recipes);
        } catch (error) {
          console.error('Error fetching favorite recipes:', error);
          Alert.alert('Error', 'Failed to fetch favorite recipes. Please try again.');
        }
      };

      fetchFavoriteRecipes();
    }, [userId])
  );

  const setAuthToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return token;
      } else {
        console.warn('No token found, cannot set Authorization header');
        return null;
      }
    } catch (error) {
      console.error('Error setting auth token:', error);
      return null;
    }
  };

  const toggleFavorite = async (recipe) => {
    try {
      const token = await setAuthToken();
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const newFavoriteStatus = recipe.favorite === 1 ? 0 : 1;
      const response = await axios.post(`${API_URL}/recipestate/favorite`, {
        user_id: userId,
        recipe_id: recipe.id,
        favorite: newFavoriteStatus,
      });

      if (response.data.success) {
        const updatedRecipes = recipes.filter(r => r.id !== recipe.id);
        setRecipes(updatedRecipes);
      } else {
        Alert.alert('Error', 'Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
      Alert.alert('Error updating favorite status');
    }
  };

  const renderRecipeItem = ({ item }) => {
    const imageUri = `${IMAGE_URL}/photos/${item.image || 'default.png'}`;
    return (
      <View style={[styles.recipeItem, { backgroundColor: colors.card }]}>
        <TouchableOpacity onPress={() => navigation.navigate('RecipeDetailsPublic', { recipeId: item.id, userId })}>
          <Image 
            source={{ uri: imageUri }} 
            style={styles.recipeImage} 
            onError={() => {
              console.error(`Failed to load image: ${imageUri}`);
              item.image = 'default.png';
            }} 
          />
        </TouchableOpacity>
        <View style={styles.recipeDetails}>
          <TouchableOpacity onPress={() => navigation.navigate('RecipeDetailsPublic', { recipeId: item.id, userId })}>
            <Text style={[styles.recipeTitle, { color: colors.text }]}>{item.title}</Text>
            <Text style={[styles.recipeTime, { color: colors.text }]}>{item.time} minutes</Text>
          </TouchableOpacity>
          <TouchableOpacity 
            style={styles.favoriteButtonContainer} 
            onPress={() => toggleFavorite(item)}
          >
            <Ionicons 
              name={item.favorite === 1 ? 'heart' : 'heart-outline'} 
              size={30} 
              color={item.favorite === 1 ? 'red' : colors.primary} 
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <FlatList
        data={recipes}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderRecipeItem}
        contentContainerStyle={styles.listContainer}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginVertical: 16,
  },
  listContainer: {
    paddingBottom: 16,
  },
  recipeItem: {
    flexDirection: 'row',
    marginBottom: 16,
    padding: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  recipeImage: {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 16,
  },
  recipeDetails: {
    flex: 1,
    justifyContent: 'center',
  },
  recipeTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  recipeTime: {
    fontSize: 14,
  },
  favoriteButtonContainer: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: [{ translateY: -12 }],
  },
});
