import React, { useEffect, useState, useCallback } from 'react';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';
import { Image, StatusBar, View, Text, StyleSheet } from 'react-native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { ThemeProvider, useTheme } from './src/context/ThemeContext';
import { API_URL } from './src/services/api';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import LoginScreen from './src/screens/LoginScreen';
import SignupScreen from './src/screens/SignupScreen';
import HomeScreen from './src/screens/HomeScreen';
import AddRecipeScreen from './src/screens/AddRecipeScreen';
import ViewRecipeScreen from './src/screens/ViewRecipeScreen';
import RecipeDetailsScreen from './src/screens/RecipeDetailsScreen';
import SettingsScreen from './src/screens/Settings';
import FavoriteScreen from './src/screens/Favorite';
import AccountDetailsScreen from './src/screens/AccountDetailsScreen';
import ChangePasswordScreen from './src/screens/ChangePasswordScreen';
import RecoverPasswordScreen from './src/screens/RecoverPasswordScreen';
import UpdateRecipeScreen from './src/screens/UpdateRecipeScreen';
import UpdateContactDetailsScreen from './src/screens/UpdateContactDetailsScreen';
import RecipeDetailsPublicScreen from './src/screens/RecipeDetailsPublicScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function HomeTabs() {
  const { colors, isDark } = useTheme();
  const iconColor = isDark ? '#818182' : colors.text;
  const [favoriteCount, setFavoriteCount] = useState(0);

  const fetchFavoriteCount = useCallback(async () => {
    try {
      const storedUserId = await AsyncStorage.getItem('user_id');
      if (!storedUserId) return;

      const url = `${API_URL}/user/${storedUserId}/favorite_count`;
      const response = await axios.get(url);
      setFavoriteCount(response.data.favoriteCount);
    } catch (error) {
      console.error('Error fetching favorite count:', error);
    }
  }, []);

  useEffect(() => {
    fetchFavoriteCount();
  }, [fetchFavoriteCount]);

  useFocusEffect(
    useCallback(() => {
      fetchFavoriteCount();
    }, [fetchFavoriteCount])
  );

  const renderFavoriteIcon = ({ color, size }) => (
    <View>
      <Image
        source={require('./assets/favorite.png')}
        style={{ width: size, height: size, tintColor: color }}
      />
      {favoriteCount > 0 && (
        <View style={styles.badge}>
          <Text style={styles.badgeText}>{favoriteCount}</Text>
        </View>
      )}
    </View>
  );

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          if (route.name === 'Settings') {
            return <Image source={require('./assets/settings.png')} style={{ width: size, height: size, tintColor: color }} />;
          }

          if (route.name === 'Home') {
            return <Image source={require('./assets/home.png')} style={{ width: size, height: size, tintColor: color }} />;
          } else if (route.name === 'AddRecipe') {
            return <Image source={require('./assets/add.png')} style={{ width: size, height: size, tintColor: color }} />;
          } else if (route.name === 'Favorite') {
            return renderFavoriteIcon({ color, size });
          } else if (route.name === 'ViewRecipe') {
            return <Image source={require('./assets/view.png')} style={{ width: size, height: size, tintColor: color }} />;
          }

          return <Ionicons name={iconName} size={size} color={iconColor} />;
        },
        tabBarActiveTintColor: colors.primary,
        tabBarInactiveTintColor: iconColor,
        tabBarStyle: { backgroundColor: colors.background },
        headerTitleAlign: 'center',
        headerTitleStyle: { fontSize: 24 },
      })}
    >
      <Tab.Screen name="Home" component={HomeScreen} />
      <Tab.Screen name="ViewRecipe" component={ViewRecipeScreen} />
      <Tab.Screen name="AddRecipe" component={AddRecipeScreen} />
      <Tab.Screen name="Favorite" component={FavoriteScreen} />
      <Tab.Screen name="Settings" component={SettingsScreen} />
    </Tab.Navigator>
  );
}

function App() {
  const { colors, isDark } = useTheme();

  useEffect(() => {
    StatusBar.setBarStyle(isDark ? 'light-content' : 'dark-content');
  }, [isDark]);

  useEffect(() => {
    // Configure Axios to set the Authorization header for all requests
    const initializeToken = async () => {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('No token found on app load, cannot set Authorization header');
      }
    };

    initializeToken();
  }, []); // Run only once on app load

  return (
    <ActionSheetProvider>
      <NavigationContainer theme={isDark ? DarkTheme : DefaultTheme}>
        <Stack.Navigator
          screenOptions={{
            headerStyle: { backgroundColor: colors.card },
            headerTintColor: colors.text,
            headerTitleAlign: 'center',
            headerTitleStyle: { fontSize: 24 },
            cardStyle: { backgroundColor: colors.background },
          }}
        >
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Signup" component={SignupScreen} />
          <Stack.Screen name="HomeTabs" component={HomeTabs} options={{ headerShown: false }} />
          <Stack.Screen name="RecipeDetails" component={RecipeDetailsScreen} />
          <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
          <Stack.Screen name="AccountDetails" component={AccountDetailsScreen} />
          <Stack.Screen name="RecoverPassword" component={RecoverPasswordScreen} />
          <Stack.Screen name="UpdateRecipe" component={UpdateRecipeScreen} />
          <Stack.Screen name="UpdateContactDetails" component={UpdateContactDetailsScreen} />
          <Stack.Screen name="RecipeDetailsPublic" component={RecipeDetailsPublicScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </ActionSheetProvider>
  );
}

export default () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    right: -10,
    top: -4,
    backgroundColor: 'red',
    borderRadius: 8,
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold',
  },
});
