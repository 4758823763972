import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Image, TextInput, Modal, TouchableWithoutFeedback, Keyboard, Alert, ScrollView } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTheme } from '../context/ThemeContext';
import { API_URL, IMAGE_URL } from '../services/api';
import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';

export default function HomeScreen({ navigation }) {
  const { colors } = useTheme();
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isSortModalVisible, setSortModalVisible] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('default');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const categories = [
    { name: 'All', image: require('../../assets/default_category.png') },
    { name: 'Desert', image: require('../../assets/cake.png') },
    { name: 'Supe', image: require('../../assets/cooking.png') },
    { name: 'Aperitiv', image: require('../../assets/appetizer.png') },
    { name: 'Fel principal', image: require('../../assets/food.png') },
    { name: 'Salata', image: require('../../assets/salad.png') },
  ];

  // Configure Axios interceptor
  useEffect(() => {
    const setAxiosInterceptor = async () => {
      const token = await AsyncStorage.getItem('token');
      axios.interceptors.request.use(
        (config) => {
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          } else {
            console.warn('No token found, cannot set Authorization header');
          }
          return config;
        },
        (error) => {
          console.error('Error in request interceptor:', error);
          return Promise.reject(error);
        }
      );
    };

    setAxiosInterceptor();

    // Cleanup function to remove interceptors on unmount
    return () => {
      axios.interceptors.request.eject(setAxiosInterceptor);
    };
  }, []); // Only run once when the component mounts

  const setAuthToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return token;
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      console.error('Error setting auth token:', error);
      return null;
    }
  };

  const fetchRecipesWithFavorites = useCallback(async () => {
    try {
      const token = await setAuthToken();
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.get(`${API_URL}/public?user_id=${userId}`);

      if (!response.data.success) {
        console.error('Failed to fetch recipes:', response.data.message);
        Alert.alert('Error', 'Failed to fetch recipes');
        return;
      }

      const recipesData = response.data.recipes;

      const recipesWithDetails = recipesData.map((recipe) => ({
        ...recipe,
        favorite: recipe.isFavorite, // 1 if favorite, 0 otherwise
        favoriteCount: recipe.favoriteCount,
      }));

      setRecipes(recipesWithDetails);
      setFilteredRecipes(recipesWithDetails); // Set all fetched recipes initially
    } catch (error) {
      console.error('Error fetching recipes:', error);
      Alert.alert('Error fetching recipes. Please try again later.');
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await setAuthToken();
        if (!token) {
          Alert.alert('Error', 'No token found. Please log in again.');
          navigation.navigate('Login');
          return;
        }

        const storedUserId = await AsyncStorage.getItem('user_id');

        if (!storedUserId) {
          Alert.alert('Error', 'No user data found. Please log in again.');
          navigation.navigate('Login');
          return;
        }

        setUserId(storedUserId);

        const response = await axios.get(`${API_URL}/user/${storedUserId}`);
        setUsername(response.data.user.username);
      } catch (error) {
        console.error('Error fetching user data:', error);
        Alert.alert('Error', 'Failed to fetch user data. Please try again.');
        navigation.navigate('Login');
      }
    };

    fetchUserData();
    fetchRecipesWithFavorites(); // Fetch recipes initially
  }, []);

  useFocusEffect(
    useCallback(() => {
      // Refetch recipes every time HomeScreen comes into focus
      fetchRecipesWithFavorites();
    }, [fetchRecipesWithFavorites])
  );

  const toggleFavorite = async (recipe) => {
    try {
      const token = await setAuthToken();

      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const newFavoriteStatus = recipe.favorite === 1 ? 0 : 1; // Inversează starea curentă de favorite

      const response = await axios.post(`${API_URL}/recipestate/favorite`, {
        user_id: userId,
        recipe_id: recipe.id,
        favorite: newFavoriteStatus,
      });

      if (response.data.success) {

        // Re-fetch recipes to update favorite count and state
        fetchRecipesWithFavorites();
      } else {
        Alert.alert('Error', 'Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
      Alert.alert('Error updating favorite status');
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = recipes.filter(recipe =>
      recipe.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredRecipes(filtered);
  };

  const handleCancelSearch = () => {
    setSearchText('');
    setFilteredRecipes(recipes);
    setIsFocused(false);
    Keyboard.dismiss();
  };

  const toggleSortModal = () => {
    setSortModalVisible(!isSortModalVisible);
  };

  const closeSortModal = () => {
    setSortModalVisible(false);
  };

  const handleSortSelection = (option) => {
    setSelectedSortOption(option);
    setSortModalVisible(false);

    if (option === 'default') {
      setFilteredRecipes(recipes);
    } else {
      let sortedRecipes = [...filteredRecipes];
      if (option === 'newest') {
        sortedRecipes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } else if (option === 'favorites') {
        sortedRecipes.sort((a, b) => b.favoriteCount - a.favoriteCount);
      } else if (option === 'oldest') {
        sortedRecipes.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      } else if (option === 'time') {
        sortedRecipes.sort((a, b) => a.time - b.time);
      }
      setFilteredRecipes(sortedRecipes);
    }
  };

  const handleCategorySelection = async (category) => {
    setSelectedCategory(category);

    if (category === 'All') {
      setFilteredRecipes(recipes); // If "All" is selected, show all recipes
      return;
    }

    try {
      const token = await setAuthToken();

      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.get(`${API_URL}/recipes/category/${category}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        const recipesInCategory = response.data.recipes;

        const recipesWithUsernames = await Promise.all(
          recipesInCategory.map(async (recipe) => {
            try {
              const userResponse = await axios.get(`${API_URL}/user/${recipe.user_id}`, {
                headers: { Authorization: `Bearer ${token}` },
              });

              return {
                ...recipe,
                username: userResponse.data.user.username || 'Unknown',
              };
            } catch (error) {
              console.error('Error fetching user details:', error);
              return {
                ...recipe,
                username: 'Unknown',
              };
            }
          })
        );

        setFilteredRecipes(recipesWithUsernames);
      } else {
        console.warn('No recipes found for this category.');
        setFilteredRecipes([]);
      }
    } catch (error) {
      console.error('Error fetching recipes by category:', error);

      if (error.response) {
        if (error.response.status === 400) {
          console.error('Bad Request. Please check your parameters or query format.');
        } else if (error.response.status === 401) {
          console.error('Unauthorized. Please check your token.');
          Alert.alert('Error', 'Your session has expired. Please log in again.');
          navigation.navigate('Login');
        } else if (error.response.status === 404) {
          setFilteredRecipes([]);
        } else {
          console.error('Server error:', error.response.data);
        }
      } else {
        console.error('Network or server is down.');
      }

      Alert.alert('Error fetching recipes by category');
    }
  };

  const renderCategoryButton = (category) => {
    const isSelected = selectedCategory === category.name;
    const buttonStyle = isSelected ? styles.categoryButtonSelected : styles.categoryButton;
    const textStyle = isSelected ? styles.categoryButtonTextSelected : styles.categoryButtonText;

    return (
      <TouchableOpacity
        key={category.name}
        style={[styles.categoryButtonBase, buttonStyle]}
        onPress={() => handleCategorySelection(category.name)}
        activeOpacity={0.7}
      >
        <Image source={category.image} style={styles.categoryImage} />
        <Text style={[textStyle, { color: colors.text }]}>{category.name}</Text>
        {isSelected && selectedCategory !== 'All' && <View style={styles.categoryButtonLine} />}
      </TouchableOpacity>
    );
  };

  const renderRecipeItem = ({ item }) => {
    const imageUri = `${IMAGE_URL}/photos/${item.image || 'default.png'}`;
    const recipeUsername = item.username || 'Unknown';

    return (
      <View style={[styles.recipeItem, { backgroundColor: colors.card }]}>
        <TouchableOpacity onPress={() => navigation.navigate('RecipeDetailsPublic', { recipeId: item.id, userId })}>
          <Image
            source={{ uri: imageUri }}
            style={styles.recipeImage}
            onError={() => {
              console.error(`Failed to load image: ${imageUri}`);
              item.image = 'default.png';
            }}
          />
        </TouchableOpacity>
        <View style={styles.recipeDetails}>
          <TouchableOpacity onPress={() => navigation.navigate('RecipeDetailsPublic', { recipeId: item.id, userId })}>
            <Text style={[styles.recipeTitle, { color: colors.text }]}>{item.title}</Text>
            <Text style={[styles.recipeTime, { color: colors.text }]}>{item.time} minutes</Text>
            <Text style={[styles.recipeUsername, { color: colors.text }]}>By {recipeUsername}</Text>
          </TouchableOpacity>
          <View style={styles.favoriteContainer}>
            <TouchableOpacity
              style={styles.favoriteButtonContainer}
              onPress={() => toggleFavorite(item)}
            >
              <Ionicons
                name={item.favorite === 1 ? 'heart' : 'heart-outline'}
                size={30}
                color={item.favorite === 1 ? 'red' : colors.primary}
              />
            </TouchableOpacity>
            {item.favoriteCount > 0 && (
              <View style={styles.favoriteBadge}>
                <Text style={styles.favoriteBadgeText}>{item.favoriteCount}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={styles.header}>
        <Text style={[styles.title, { color: colors.text }]}>Welcome, {username}!</Text>
      </View>

      {/* Search Bar */}
      <View style={styles.searchContainer}>
        <View style={[styles.searchInputContainer, { backgroundColor: colors.card }]}>
          <Ionicons name="search" size={20} color={colors.text} style={styles.searchIcon} />
          <TextInput
            style={[styles.searchInput, { color: colors.text }]}
            placeholder="Start a new search"
            placeholderTextColor={colors.text}
            value={searchText}
            onChangeText={handleSearch}
            onFocus={() => setIsFocused(true)}
          />
        </View>
        {isFocused && (
          <TouchableOpacity onPress={handleCancelSearch} style={styles.cancelButton}>
            <Text style={{ color: colors.text }}>Cancel</Text>
          </TouchableOpacity>
        )}
      </View>

      {/* Sort and Filter Buttons */}
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.filterButton}>
          <Ionicons name="filter" size={24} color={colors.text} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.sortButton} onPress={toggleSortModal}>
          <Ionicons
            name="funnel"
            size={24}
            color={selectedSortOption !== 'default' ? 'red' : colors.text}
          />
        </TouchableOpacity>
      </View>

      {/* Scroll Menu */}
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.categoryScroll}
        contentContainerStyle={styles.categoryScrollContainer}
      >
        {categories.map((category) => renderCategoryButton(category))}
      </ScrollView>

      <FlatList
        data={filteredRecipes}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderRecipeItem}
        contentContainerStyle={styles.listContainer}
      />

      {/* Sort Modal */}
      <Modal
        visible={isSortModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={closeSortModal}
      >
        <TouchableWithoutFeedback onPress={closeSortModal}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View style={[styles.sortModalContent, { backgroundColor: colors.card }]}>
                <View style={styles.modalHeader}>
                  <Text style={[styles.modalTitle, { color: colors.text }]}>Sort</Text>
                  <TouchableOpacity onPress={closeSortModal} style={styles.closeButton}>
                    <Ionicons name="close" size={24} color={colors.text} />
                  </TouchableOpacity>
                </View>
                <View style={styles.modalDivider} />

                <TouchableOpacity onPress={() => handleSortSelection('default')} style={styles.sortOption}>
                  <Ionicons
                    name={selectedSortOption === 'default' ? "checkmark-circle" : "ellipse-outline"}
                    size={24}
                    color={colors.primary}
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Default</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => handleSortSelection('newest')} style={styles.sortOption}>
                  <Ionicons
                    name={selectedSortOption === 'newest' ? "checkmark-circle" : "ellipse-outline"}
                    size={24}
                    color={colors.primary}
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Newest</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => handleSortSelection('favorites')} style={styles.sortOption}>
                  <Ionicons
                    name={selectedSortOption === 'favorites' ? "checkmark-circle" : "ellipse-outline"}
                    size={24}
                    color={colors.primary}
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Number of Favorites</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => handleSortSelection('oldest')} style={styles.sortOption}>
                  <Ionicons
                    name={selectedSortOption === 'oldest' ? "checkmark-circle" : "ellipse-outline"}
                    size={24}
                    color={colors.primary}
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Oldest</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => handleSortSelection('time')} style={styles.sortOption}>
                  <Ionicons
                    name={selectedSortOption === 'time' ? "checkmark-circle" : "ellipse-outline"}
                    size={24}
                    color={colors.primary}
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Preparation Time</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  searchInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    backgroundColor: '#fff',
  },
  searchIcon: {
    marginRight: 5,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
  },
  cancelButton: {
    marginLeft: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  filterButton: {
    backgroundColor: '#444',
    padding: 10,
    borderRadius: 8,
  },
  sortButton: {
    backgroundColor: '#444',
    padding: 10,
    borderRadius: 8,
  },
  categoryScroll: {
    marginBottom: 10,
    maxHeight: 100,
  },
  categoryButtonBase: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    marginHorizontal: 0,
    marginVertical: 0,
  },
  categoryButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    flexShrink: 0,
  },
  categoryImage: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  categoryButtonText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 4,
  },
  categoryButtonTextSelected: {
    fontSize: 14,
    textAlign: 'center',
    color: 'red',
    marginTop: 4,
  },
  listContainer: {
    paddingBottom: 16,
  },
  recipeItem: {
    flexDirection: 'row',
    marginBottom: 8,
    padding: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  recipeImage: {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 16,
  },
  recipeDetails: {
    flex: 1,
    justifyContent: 'center',
  },
  recipeTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  recipeUsername: {
    fontSize: 16,
    fontStyle: 'italic',
    marginBottom: 4,
  },
  recipeTime: {
    fontSize: 14,
  },
  favoriteContainer: {
    position: 'relative',
  },
  favoriteButtonContainer: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: [{ translateY: -12 }],
  },
  favoriteBadge: {
    position: 'absolute',
    right: -10,
    top: -25,
    backgroundColor: 'red',
    borderRadius: 10,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  favoriteBadgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  sortModalContent: {
    width: '100%',
    padding: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    alignItems: 'center',
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  modalDivider: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  sortOption: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  sortOptionText: {
    fontSize: 16,
    marginLeft: 10,
  },
  closeButton: {
    backgroundColor: '#FF0000',
    borderRadius: 12,
    padding: 5,
  },
  categoryButtonLine: {
    height: 2,
    backgroundColor: 'red',
    width: '100%',
    marginTop: 4,
  },
});
