import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Platform, KeyboardAvoidingView, ScrollView, Alert, Modal, Image, TouchableWithoutFeedback } from 'react-native';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useRoute } from '@react-navigation/native';
import { API_URL, IMAGE_URL } from '../services/api';
import { Picker } from '@react-native-picker/picker';
import { useTheme } from '../context/ThemeContext';

// Configurare Axios pentru a seta automat header-ul Authorization
axios.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found, cannot set Authorization header'); // Debugging log
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Funcție pentru a seta header-ul Authorization pentru toate cererile
const setAuthToken = async () => {
  try {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return token;
    } else {
      throw new Error('No token found');
    }
  } catch (error) {
    console.error('Error setting auth token:', error);
    return null;
  }
};

export default function UpdateRecipeScreen() {
  const { colors } = useTheme();
  const [title, setTitle] = useState('');
  const [ingredients, setIngredients] = useState([{ ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
  const [instructions, setInstructions] = useState('');
  const [time, setTime] = useState('');
  const [image, setImage] = useState(null);
  const { showActionSheetWithOptions } = useActionSheet();
  const navigation = useNavigation();
  const route = useRoute();
  const { recipeId } = route.params;
  const [userId, setUserId] = useState(null);
  const [inputHeight, setInputHeight] = useState(100);
  const [selectedIngredientIndex, setSelectedIngredientIndex] = useState(null);
  const [isPickerVisible, setPickerVisibility] = useState(false);
  const [category, setCategory] = useState('');
  const [isCategoryPickerVisible, setCategoryPickerVisibility] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const getUserId = async () => {
      const id = await AsyncStorage.getItem('user_id');
      setUserId(id);
    };

    getUserId();

    if (Platform.OS !== 'web') {
      (async () => {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to make this work!');
        }
      })();
    }

    fetchRecipeDetails();
  }, []);

  const fetchRecipeDetails = async () => {
    try {
      const token = await setAuthToken(); // Ensure token is set
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.get(`${API_URL}/recipes/${recipeId}`);
      const recipe = response.data.recipe;
      setTitle(recipe.title);
      setIngredients(recipe.ingredients.map(ingredient => ({
        ingrediente: ingredient.ingrediente,
        cantitate: ingredient.cantitate.toString(),
        unitate_de_masura: ingredient.unitate_de_masura,
      })));
      setInstructions(recipe.instructions);
      setTime(recipe.time.toString());
      setCategory(recipe.categories || ''); // Set category
      setImage(recipe.image ? { uri: `${IMAGE_URL}/photos/${recipe.image}` } : null);
    } catch (error) {
      console.error('Error fetching recipe details:', error);
      Alert.alert('Error fetching recipe details');
    }
  };

  const handleUpdateRecipe = async () => {
    if (!userId) {
      Alert.alert('Error', 'User not logged in');
      return;
    }
  
    if (!title || !instructions || !time || !category || ingredients.some(ing => !ing.ingrediente)) {
      Alert.alert('Error', 'All fields are required');
      return;
    }
  
    const timeInMinutes = parseInt(time, 10);
  
    try {
      const token = await setAuthToken(); // Ensure token is set
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }
  
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('title', title);
      formData.append('instructions', instructions);
      formData.append('time', timeInMinutes);
      formData.append('categories', category); // Include category
      formData.append('ingredients', JSON.stringify(ingredients));
  
      // Verificăm dacă imaginea este una nouă sau dacă se păstrează imaginea existentă
      if (image && image.uri && !image.uri.startsWith(`${IMAGE_URL}/photos/`)) {
        // Dacă imaginea a fost schimbată, o adăugăm în formData
        if (Platform.OS === 'web') {
          formData.append('image', image.file);
        } else {
          formData.append('image', {
            uri: image.uri,
            type: 'image/jpeg',
            name: image.uri.split('/').pop(),
          });
        }
      } else {
        // Dacă imaginea nu a fost schimbată, trimitem doar numele fișierului din baza de date
        formData.append('existingImage', image.uri.split('/').pop());
      }
  
      const response = await axios.put(`${API_URL}/recipes/${recipeId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.success) {
        setTitle('');
        setIngredients([{ ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
        setInstructions('');
        setTime('');
        setCategory(''); // Reset category
        setImage(null);
        navigation.navigate('ViewRecipe');
      } else {
        Alert.alert('Error updating recipe');
      }
    } catch (error) {
      console.error('Error updating recipe:', error);
      Alert.alert('Error updating recipe');
    }
  };
  

  const handleChoosePhoto = async (option) => {
    let result = null;
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      if (option === 'Take Photo') {
        const { status } = await ImagePicker.requestCameraPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera permissions to make this work!');
          return;
        }
        result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
      } else if (option === 'Choose from Library') {
        result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
      }
    }

    if (result && !result.canceled) {
      setImage(result.assets[0]);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage({
        uri: URL.createObjectURL(file),
        file: file, // Store the file to add to FormData
        name: file.name,
        type: file.type,
      });
    }
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, { ingrediente: '', cantitate: '', unitate_de_masura: '' }]);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][field] = value;
    setIngredients(newIngredients);
  };

  const handlePickerChange = (value) => {
    if (selectedIngredientIndex !== null) {
      handleIngredientChange(selectedIngredientIndex, 'unitate_de_masura', value);
    }
    setPickerVisibility(false);
  };

  const showImagePickerOptions = () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      const options = ['Take Photo', 'Choose from Library', 'Cancel'];
      const cancelButtonIndex = 2;

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            handleChoosePhoto('Take Photo');
          } else if (buttonIndex === 1) {
            handleChoosePhoto('Choose from Library');
          }
        },
      );
    }
  };

  const handleUnitPress = (index) => {
    setSelectedIngredientIndex(index);
    setPickerVisibility(true);
  };

  const handleCategoryPress = () => {
    setCategoryPickerVisibility(true);
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 64 : 0}
    >
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <TextInput
            style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
            placeholder="Title"
            value={title}
            onChangeText={setTitle}
            placeholderTextColor={colors.textSecondary}
          />
          {ingredients.map((ingredient, index) => (
            <View key={index} style={styles.ingredientContainer}>
              <TextInput
                style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
                placeholder="Ingredient"
                value={ingredient.ingrediente}
                onChangeText={(text) => handleIngredientChange(index, 'ingrediente', text)}
                placeholderTextColor={colors.textSecondary}
              />
              <View style={styles.quantityUnitContainer}>
                <TextInput
                  style={[styles.input, styles.quantityInput, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
                  placeholder="Quantity"
                  value={ingredient.cantitate}
                  onChangeText={(text) => handleIngredientChange(index, 'cantitate', text)}
                  placeholderTextColor={colors.textSecondary}
                  keyboardType="numeric"
                />
                <TouchableOpacity style={[styles.input, styles.unitInput, { backgroundColor: colors.card }]} onPress={() => handleUnitPress(index)}>
                  <Text style={[styles.unitInputText, { color: colors.text }]}>{ingredient.unitate_de_masura || 'Select Unit'}</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
          <TouchableOpacity onPress={handleAddIngredient} style={[styles.addIngredientButton, { backgroundColor: colors.primary }]}>
            <Text style={styles.addIngredientButtonText}>Add More Ingredients</Text>
          </TouchableOpacity>
          <TextInput
            style={[styles.textArea, { height: Math.max(100, inputHeight), color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
            placeholder="Instructions"
            value={instructions}
            onChangeText={setInstructions}
            multiline
            onContentSizeChange={(event) => {
              setInputHeight(event.nativeEvent.contentSize.height);
            }}
            placeholderTextColor={colors.textSecondary}
          />
          <TextInput
            style={[styles.input, { color: colors.text, borderColor: colors.border, backgroundColor: colors.card }]}
            placeholder="Set Time (minutes)"
            value={time}
            onChangeText={setTime}
            keyboardType="numeric"
            placeholderTextColor={colors.textSecondary}
          />
          <TouchableOpacity 
            style={[styles.input, styles.unitCategory, { backgroundColor: colors.card }]} 
            onPress={() => setCategoryPickerVisibility(true)}
          >
            <Text style={[styles.unitInputText, { color: colors.text }]}>
              {category || 'Select Category'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={showImagePickerOptions} style={[styles.addRecipeButton, { backgroundColor: colors.primary }]}>
            <Text style={styles.addRecipeButtonText}>Update Image</Text>
          </TouchableOpacity>
          {Platform.OS === 'web' && (
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
          )}
          {image && (
            <>
              <Image source={{ uri: image.uri }} style={styles.image} />
            </>
          )}
          <TouchableOpacity onPress={handleUpdateRecipe} style={[styles.addRecipeButton, { backgroundColor: colors.primary }]}>
            <Text style={styles.addRecipeButtonText}>Update Recipe</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>

      <Modal
        visible={isPickerVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setPickerVisibility(false)}
      >
        <TouchableWithoutFeedback onPress={() => setPickerVisibility(false)}>
          <View style={styles.pickerModal}>
            <TouchableWithoutFeedback>
              <View style={[styles.pickerContainer, { backgroundColor: colors.cardSelect }]}>
                <Picker
                  selectedValue={selectedIngredientIndex !== null ? ingredients[selectedIngredientIndex]?.unitate_de_masura : ''}
                  onValueChange={handlePickerChange}
                  style={{ color: colors.textSelect }}
                >
                  <Picker.Item label="Select Unit" value="" />
                  <Picker.Item label="gr" value="gr" />
                  <Picker.Item label="Kg" value="Kg" />
                  <Picker.Item label="ml" value="ml" />
                  <Picker.Item label="L" value="L" />
                  <Picker.Item label="Bucata/i" value="Bucata/i" />
                  <Picker.Item label="Lingurita/e" value="Lingurita/e" />
                  <Picker.Item label="Lingura/i" value="Lingura/i" />
                  <Picker.Item label="Pliculet/e" value="Pliculet/e" />
                  <Picker.Item label="Pachet/e" value="Pachet/e" />
                </Picker>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      
      <Modal
        visible={isCategoryPickerVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setCategoryPickerVisibility(false)}
      >
        <TouchableWithoutFeedback onPress={() => setCategoryPickerVisibility(false)}>
          <View style={styles.pickerModal}>
            <TouchableWithoutFeedback>
              <View style={[styles.pickerContainer, { backgroundColor: colors.cardSelect }]}>
                <Picker
                  selectedValue={category}
                  onValueChange={(itemValue) => {
                    setCategory(itemValue);
                    setCategoryPickerVisibility(false);
                  }}
                  style={{ color: colors.textSelect }}
                >
                  <Picker.Item label="Select Category" value="" />
                  <Picker.Item label="Desert" value="Desert" />
                  <Picker.Item label="Supe" value="Supe" />
                  <Picker.Item label="Aperitiv" value="Aperitiv" />
                  <Picker.Item label="Fel Principal" value="Fel Principal" />
                  <Picker.Item label="Salată" value="Salată" />
                </Picker>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    padding: 20,
  },
  ingredientContainer: {
    marginBottom: 12,
  },
  input: {
    height: 50,
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
    justifyContent: 'center',
  },
  quantityUnitContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  quantityInput: {
    flex: 0.48,
  },
  unitInput: {
    flex: 0.48,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  unitInputText: {
    textAlign: 'center',
    flex: 1,
    justifyContent: 'center',
    lineHeight: 50,
  },
  textArea: {
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    paddingTop: 10,
    fontSize: 16,
    textAlignVertical: 'top',
  },
  addIngredientButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  addIngredientButtonText: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  addRecipeButton: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  addRecipeButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  unitCategory: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#D3D3D3',
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    height: 50,
    width: '100%',
  },
  image: {
    width: '100%',
    height: 200,
    marginTop: 16,
    borderRadius: 10,
    marginBottom: 16,
  },
  pickerModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  pickerContainer: {
    marginHorizontal: 20,
    borderRadius: 10,
    padding: 20,
    width: '100%',
    maxWidth: 600,
  },
});
