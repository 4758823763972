import React, { useState } from 'react';
import { View, Text, TextInput, Alert, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Keyboard, Platform, ActivityIndicator } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext'; // Import useTheme

export default function ChangePasswordScreen({ navigation }) {
  const { colors } = useTheme(); // Use theme colors
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading indicator
  const [errorMessage, setErrorMessage] = useState(''); // Error message

  const setAuthToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return token;
      } else {
        console.warn('No token found, cannot set Authorization header');
        return null;
      }
    } catch (error) {
      console.error('Error setting auth token:', error);
      return null;
    }
  };

  const handleChangePassword = async () => {
    const userId = await AsyncStorage.getItem('user_id');

    if (!userId) {
      Alert.alert('Error', 'User not logged in');
      return;
    }

    if (!currentPassword || !newPassword) {
      setErrorMessage('All fields are required');
      return;
    }

    if (newPassword.length < 6) {
      setErrorMessage('New password must be at least 6 characters');
      return;
    }

    setLoading(true); // Start loading indicator
    setErrorMessage(''); // Reset error messages

    try {
      const token = await setAuthToken();
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.post(`${API_URL}/change-password`, {
        user_id: userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.data.success) {
        Alert.alert('Success', 'Password changed successfully', [
          { text: 'OK', onPress: () => setTimeout(() => navigation.navigate('Settings'), 1000) }
        ]);
        setCurrentPassword('');
        setNewPassword('');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred while changing the password. Please try again.');
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const renderContent = () => (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

      <TextInput
        style={[styles.input, { backgroundColor: colors.card, color: colors.text, borderColor: colors.border }]}
        placeholder="Current Password"
        value={currentPassword}
        onChangeText={setCurrentPassword}
        secureTextEntry
        placeholderTextColor={colors.textSecondary}
      />
      <TextInput
        style={[styles.input, { backgroundColor: colors.card, color: colors.text, borderColor: colors.border }]}
        placeholder="New Password"
        value={newPassword}
        onChangeText={setNewPassword}
        secureTextEntry
        placeholderTextColor={colors.textSecondary}
      />

      {loading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : (
        <TouchableOpacity style={[styles.button, { backgroundColor: colors.primary }]} onPress={handleChangePassword}>
          <Text style={styles.buttonText}>Change Password</Text>
        </TouchableOpacity>
      )}
    </View>
  );

  return Platform.OS === 'web' ? (
    renderContent()
  ) : (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {renderContent()}
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 25,
    marginBottom: 20,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  button: {
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
    textAlign: 'center',
  },
});
