import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, Alert, FlatList, TouchableOpacity, TextInput, Keyboard, Modal, Platform, TouchableWithoutFeedback } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { API_URL, IMAGE_URL } from '../services/api';
import { useTheme } from '../context/ThemeContext';

// Configurare Axios pentru a seta automat header-ul Authorization
axios.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found, cannot set Authorization header'); // Debugging log
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Funcție pentru a seta header-ul Authorization pentru toate cererile
const setAuthToken = async () => {
  try {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return token;
    } else {
      throw new Error('No token found');
    }
  } catch (error) {
    console.error('Error setting auth token:', error);
    return null;
  }
};

export default function ViewRecipeScreen({ navigation }) {
  const { colors, isDark } = useTheme();
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [isSortModalVisible, setSortModalVisible] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('default');

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await AsyncStorage.getItem('user_id');
      setUserId(id);
    };

    fetchUserId();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      if (userId) {
        fetchRecipes();
      }
    }, [userId])
  );

  const fetchRecipes = async () => {
    try {
      const token = await setAuthToken(); // Ensure token is set
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.get(`${API_URL}/recipes`, {
        params: {
          user_id: userId,
        }
      });
      setRecipes(response.data.recipes);
      setFilteredRecipes(response.data.recipes);
    } catch (error) {
      console.error(error);
      Alert.alert('Error fetching recipes');
    }
  };

  const fetchSortedRecipes = async (sortOption) => {
    try {
      const token = await setAuthToken(); // Ensure token is set
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.get(`${API_URL}/recipes/${userId}/sort`, {
        params: { sort: sortOption },
      });
      setRecipes(response.data.recipes);
      setFilteredRecipes(response.data.recipes);
    } catch (error) {
      console.error(`Error fetching sorted recipes: ${error.message}`);
      Alert.alert('Error fetching recipes');
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    if (text === '') {
      setFilteredRecipes(recipes);
    } else {
      const filtered = recipes.filter(recipe =>
        recipe.title.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredRecipes(filtered);
    }
  };

  const handleCancelSearch = () => {
    setSearchText('');
    setFilteredRecipes(recipes);
    setIsFocused(false);
    Keyboard.dismiss();
  };

  const handleDeleteRecipe = async () => {
    if (!selectedRecipe) return;

    try {
      const token = await setAuthToken(); // Ensure token is set
      if (!token) {
        Alert.alert('Error', 'No token found. Please log in again.');
        navigation.navigate('Login');
        return;
      }

      const response = await axios.delete(`${API_URL}/recipes/${selectedRecipe.id}`);
      if (response.data.success) {
        const updatedRecipes = recipes.filter(recipe => recipe.id !== selectedRecipe.id);
        setRecipes(updatedRecipes);
        setFilteredRecipes(updatedRecipes);
        setDeleteConfirmVisible(false);
        setSettingsModalVisible(false);
        setSelectedRecipe(null);
      } else {
        Alert.alert('Error', response.data.message || 'Failed to delete the recipe. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting recipe:', error);
      Alert.alert('Error', 'An error occurred while deleting the recipe.');
    }
  };

  const toggleSortModal = () => {
    setSortModalVisible(!isSortModalVisible);
  };

  const closeSortModal = () => {
    setSortModalVisible(false);
  };

  const handleSortSelection = (option) => {
    setSelectedSortOption(option);
    setSortModalVisible(false);
  
    if (option !== 'default') {
      fetchSortedRecipes(option);
    } else {
      fetchRecipes();  // Default sorting, fetch without any sorting
    }
  };

  const renderRecipeItem = ({ item }) => {
    const imageUri = `${IMAGE_URL}/photos/${item.image || 'default.png'}`;
    return (
      <View style={[styles.recipeItem, { backgroundColor: colors.card }]}>
        <TouchableOpacity onPress={() => navigation.navigate('RecipeDetails', { recipeId: item.id })}>
          <Image 
            source={{ uri: imageUri }} 
            style={styles.recipeImage} 
            onError={() => {
              console.error(`Failed to load image: ${imageUri}`);
              item.image = 'default.png';
            }} 
          />
        </TouchableOpacity>
        <View style={styles.recipeDetails}>
          <TouchableOpacity onPress={() => navigation.navigate('RecipeDetails', { recipeId: item.id })}>
            <Text style={[styles.recipeTitle, { color: colors.text }]}>{item.title}</Text>
            <Text style={[styles.recipeTime, { color: colors.text }]}>{item.time} minutes</Text>
          </TouchableOpacity>
          <TouchableOpacity 
            style={styles.settingsButtonContainer} 
            onPress={() => {
              setSelectedRecipe(item);
              setSettingsModalVisible(true);
            }}
          >
            <Ionicons name="settings-outline" size={30} color={colors.primary} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <View style={styles.searchContainer}>
        <View 
          style={[
            styles.searchInputContainer, 
            { backgroundColor: colors.background === '#ffffff' ? '#ffffff' : '#333' }
          ]}
        >
          <Ionicons name="search" size={20} color={colors.text} style={styles.searchIcon} />
          <TextInput
            style={[
              styles.searchInput, 
              { 
                color: colors.text,
                ...(Platform.OS === 'web' && { outlineStyle: 'none' })
              }
            ]}
            placeholder="Start a new search"
            placeholderTextColor={colors.text}
            value={searchText}
            onChangeText={handleSearch}
            onFocus={() => setIsFocused(true)}
          />
        </View>
        {isFocused && (
          <TouchableOpacity onPress={handleCancelSearch} style={styles.cancelButton}>
            <Text style={{ color: colors.text }}>Anulează</Text>
          </TouchableOpacity>
        )}
      </View>

      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.filterButton}>
          <Ionicons name="filter" size={24} color={colors.text} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.sortButton} onPress={toggleSortModal}>
          <Ionicons 
            name="funnel" 
            size={24} 
            color={selectedSortOption !== 'default' ? '#FF0000' : colors.text} 
          />
        </TouchableOpacity>
      </View>

      <FlatList
        data={filteredRecipes}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderRecipeItem}
        contentContainerStyle={styles.listContainer}
      />

      {/* Sort Modal */}
      <Modal
        visible={isSortModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={closeSortModal}
      >
        <TouchableWithoutFeedback onPress={closeSortModal}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View style={[styles.sortModalContent, { backgroundColor: colors.card }]}>
                <View style={styles.modalHeader}>
                  <Text style={[styles.modalTitle, { color: colors.text }]}>Sortează</Text>
                  <TouchableOpacity onPress={closeSortModal} style={styles.closeButton}>
                    <Ionicons name="close" size={24} color={colors.text} />
                  </TouchableOpacity>
                </View>
                <View style={styles.modalDivider} />
                
                <TouchableOpacity onPress={() => handleSortSelection('default')} style={styles.sortOption}>
                  <Ionicons 
                    name={selectedSortOption === 'default' ? "checkmark-circle" : "ellipse-outline"} 
                    size={24} 
                    color={colors.primary} 
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Implicit</Text>
                </TouchableOpacity>
                
                <TouchableOpacity onPress={() => handleSortSelection('newest')} style={styles.sortOption}>
                  <Ionicons 
                    name={selectedSortOption === 'newest' ? "checkmark-circle" : "ellipse-outline"} 
                    size={24} 
                    color={colors.primary} 
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Cele mai noi</Text>
                </TouchableOpacity>
                
                <TouchableOpacity onPress={() => handleSortSelection('favorites')} style={styles.sortOption}>
                  <Ionicons 
                    name={selectedSortOption === 'favorites' ? "checkmark-circle" : "ellipse-outline"} 
                    size={24} 
                    color={colors.primary} 
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Număr de Favorite</Text>
                </TouchableOpacity>
                
                <TouchableOpacity onPress={() => handleSortSelection('oldest')} style={styles.sortOption}>
                  <Ionicons 
                    name={selectedSortOption === 'oldest' ? "checkmark-circle" : "ellipse-outline"} 
                    size={24} 
                    color={colors.primary} 
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Cele mai vechi</Text>
                </TouchableOpacity>
                
                <TouchableOpacity onPress={() => handleSortSelection('time')} style={styles.sortOption}>
                  <Ionicons 
                    name={selectedSortOption === 'time' ? "checkmark-circle" : "ellipse-outline"} 
                    size={24} 
                    color={colors.primary} 
                  />
                  <Text style={[styles.sortOptionText, { color: colors.text }]}>Timp de Preparare</Text>
                </TouchableOpacity>
                
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      {/* Existing Modals for Settings and Delete Confirmation */}
      <Modal
        visible={isSettingsModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={[styles.modalContent, { backgroundColor: colors.card }]}>
            <Text style={[styles.modalTitle, { color: colors.text }]}>Settings</Text>
            <TouchableOpacity 
              style={[styles.modalButton, { backgroundColor: colors.primary }]}
              onPress={() => {
                setSettingsModalVisible(false);
                navigation.navigate('UpdateRecipe', { recipeId: selectedRecipe.id });
              }}
            >
              <Text style={styles.modalButtonText}>Update Recipe</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.modalButton, { backgroundColor: colors.primary }]}
              onPress={() => {
                setSettingsModalVisible(false);
                setDeleteConfirmVisible(true);
              }}
            >
              <Text style={styles.modalButtonText}>Delete Recipe</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.modalButton, { backgroundColor: colors.primary }]}
              onPress={() => setSettingsModalVisible(false)}
            >
              <Text style={styles.modalButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <Modal
        visible={isDeleteConfirmVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={[styles.modalContent, { backgroundColor: colors.card }]}>
            <Text style={[styles.modalTitle, { color: colors.text }]}>Confirm Delete</Text>
            <Text style={[styles.modalText, { color: colors.text }]}>Are you sure you want to delete this recipe?</Text>
            <TouchableOpacity 
              style={[styles.modalButton, { backgroundColor: colors.primary }]}
              onPress={handleDeleteRecipe}
            >
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.modalButton, { backgroundColor: colors.primary }]}
              onPress={() => {
                setDeleteConfirmVisible(false);
              }}
            >
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  searchInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
  },
  searchIcon: {
    marginRight: 5,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
  },
  cancelButton: {
    marginLeft: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  filterButton: {
    backgroundColor: '#444',
    padding: 10,
    borderRadius: 8,
  },
  sortButton: {
    backgroundColor: '#444',
    padding: 10,
    borderRadius: 8,
  },
  listContainer: {
    paddingBottom: 16,
  },
  recipeItem: {
    flexDirection: 'row',
    marginBottom: 16,
    padding: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  recipeImage: {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 16,
  },
  recipeDetails: {
    flex: 1,
    justifyContent: 'center',
  },
  recipeTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  recipeTime: {
    fontSize: 14,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  sortModalContent: {
    width: '100%',
    padding: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    alignItems: 'center',
    minHeight: 350,  
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  modalDivider: {
    width: '100%',
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  sortOption: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  sortOptionText: {
    fontSize: 16,
    marginLeft: 10,
  },
  closeButton: {
    backgroundColor: '#FF0000',
    borderRadius: 12,
    padding: 5,
  },
  modalContent: {
    width: '80%',
    padding: 20,
    borderRadius: 8,
    alignItems: 'center',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 20,
  },
  modalButton: {
    width: '100%',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: 10,
  },
  modalButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  settingsButtonContainer: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: [{ translateY: -12 }],
  },
});
